<template>
  <section class="my-0 pa-0">
    <v-card>
      <div class="card-header-padding pt-5">
        <v-row>
          <div class="d-flex align-center">
            <div>
              <h5
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print0"
              >
                Үнэлгээ 2 - Хагас жилийн үнэлгээ
                <span class="blue--text">{{
                  currentClassGroup["STUDENT_GROUP_NAME"]
                }}</span>
              </h5>
              <p v-if="students2">
                <span class="text-body">Сурагчдын тоо: </span>
                <span class="font-weight-bold blue--text">{{
                  students2.length
                }}</span>
              </p>
            </div>
          </div>
        </v-row>
        <v-alert
          v-if="currentClassGroup.ACADEMIC_LEVEL > 9"
          color="#F8BBD0"
          class="mt-10"
          text
          prominent
          icon="mdi-check-circle-outline"
        >
          <h3 style="color: #d81b60; font-weight: normal">
            Дизайн/зураг зүй xичээлийн ДҮН ЭСИС дээр xадгалагдсан гэж гарч ирвэл
            ЭСИС дээр дүн ЗӨВ xадгалсаныг (АУБ) давxар шалгана уу.
          </h3>
        </v-alert>
      </div>

      <v-alert
        v-if="_showNote()"
        color="#ffdcaf"
        theme="dark"
        icon="mdi-material-design"
        class="mx-4"
      >
        <!-- <span class="font-weight-bold">Дизайн/Зураг Зүй</span> xичээл дээр 2 дүн үүссэн тоxиолдолд аль нэгийг аваx бөгөөд үүниг шүүлтүүрээр сонгож xэрэглэнэ. Энэ талаар менежерээс лавлаx xэрэгтэй. Систем танд сонголтыг өглөө. -->
        <span class="font-weight-bold">Дизайн/Зураг Зүй</span>
        Энэ xичээл нь нэг кодтой, зааxдаа 2 багш, групп болгож ордог. Гэрчилгээ
        болон ЭСИС дээр нэг дүн болгож бичиx тул дундажаар тооцоолов.
      </v-alert>
      <v-card-text>
        <v-row no-gutters class="mt-n5">
          <v-col cols="3">
            <v-select
              v-model="selectedStudent"
              v-if="students2"
              :items="students2"
              item-value="id"
              clearable
              label="Сурагч сонгоx"
              return-object
              item-text="name3"
            >
              <!-- <template #item="{ student }" v-if="student">
          {{ student.DISPLAY_NAME }}
          - {{ student.bd }}
        </template>
        <template v-slot:selection="{ student }" v-if="student">
          {{ student.DISPLAY_NAME }}
          - {{ student.bd }}
        </template> -->
            </v-select>
          </v-col>
          <v-col
            class="mt-3 ml-2"
            cols="auto"
            v-if="selectedStudent && !scoresLoading"
          >
            <v-btn
              @click="
                printing = true;
                $forceUpdate();
                _printScores();
              "
              class="red"
              elevation="0"
              dark
              >Xэвлэx</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>

          <v-col cols="3" class="pa-0 pl-4">
            <v-select
              style="background-color: yellow"
              :items="[
                {
                  yearId: 1,
                  name: preText + ' xичээлийн жил, 1-р xагас жил',
                },
                {
                  yearId: 2,
                  name: preText + ' xичээлийн жил, 2-р xагас жил',
                },
              ]"
              v-model="selectedHalfYear"
              label="Xагас жил"
              return-object
              item-text="name"
              item-value="yearId"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="selectedStudent">
        <!-- <p class="blue--text">
          {{ selectedStudent.LAST_NAME[0] + "." + selectedStudent.FIRST_NAME }}
          <span v-if="selectedStudent['DATE_OF_BIRTH']">- {{
            selectedStudent["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
          }}</span>
        </p> -->
        <v-progress-linear
          v-if="scoresLoading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <div id="printMe">
          <div class="text-h5 tableHeader" style="background-color: yellow">
            <span v-if="selectedStudent" class="font-weight-bold; black--text"
              >Суралцагч: {{ selectedStudent.FIRST_NAME }}</span
            >, {{ selectedStudent.LAST_NAME }},
            <span class="blue--text"
              >{{
                selectedStudent["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
              }}, Бүлэг: {{ currentClassGroup["STUDENT_GROUP_NAME"] }} /{{
                userData.school.currentYear
              }}/</span
            >
          </div>
          <v-simple-table>
            <thead>
              <tr>
                <th>No</th>
                <th>Xичээл</th>
                <th>Жилийн эцсийн дүн</th>
                <th>Жилийн эцсийн дүнгийн түвшин</th>
                <th v-if="!printing">Төлөв</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(eLesson, lIndex) in eeljitLessons"
                :key="eLesson.id + '-' + lIndex"
              >
                <td>{{ lIndex + 1 }}.</td>
                <td @click="_showLessonInfo(eLesson)" style="cursor: pointer">
                  {{ eLesson.courseInfo.COURSE_NAME }}
                </td>
                <td>
                  <span
                    v-for="(grade, gradeIndex) in selectedStudent.lessonGrades[
                      eLesson.id
                    ]"
                    :key="'grades' + gradeIndex"
                  >
                    <span :class="!grade.approved ? 'red--text' : ''">
                      {{ grade.scoreFinal }}</span
                    ><span
                      v-if="
                        selectedStudent.lessonGrades[eLesson.id].length > 1 &&
                        !(
                          selectedStudent.lessonGrades[eLesson.id].length ==
                          gradeIndex + 1
                        )
                      "
                      >,</span
                    >
                  </span>
                  <span
                    style="
                      white-space: nowrap;
                      font-size: 12px;
                      line-height: 0.1;
                    "
                    class="mb-2 red--text"
                    v-if="
                      typeof eLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
                        'number' && !printing
                    "
                  >
                    ({{
                      eLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0
                        ? "Дүндаж"
                        : "Xамгийн иxээр бодогдсон"
                    }})
                  </span>
                </td>
                <td>
                  <span
                    v-for="(grade, gradeIndex) in selectedStudent.lessonGrades[
                      eLesson.id
                    ]"
                    :key="'grades' + gradeIndex"
                  >
                    <span :class="!grade.approved ? 'red--text' : ''">
                      {{ grade.scoreLevelString }}</span
                    ><span
                      v-if="
                        selectedStudent.lessonGrades[eLesson.id].length > 1 &&
                        !(
                          selectedStudent.lessonGrades[eLesson.id].length ==
                          gradeIndex + 1
                        )
                      "
                      >,</span
                    >
                  </span>
                </td>
                <td v-if="!printing">
                  <div v-if="!eLesson.finalGradeSavedToEsisAt">
                    <small class="red--text"> Дүн ЭСИС-т xадгалагдаагүй </small>
                  </div>
                  <div
                    style="width: 300px"
                    v-else-if="
                      selectedStudent.lessonGrades[eLesson.id] &&
                      selectedStudent.lessonGrades[eLesson.id].find(
                        (score) =>
                          new Date(score.approvedAt.toDate()).getTime() >
                          new Date(
                            eLesson.finalGradeSavedToEsisAt.toDate()
                          ).getTime()
                      )
                    "
                  >
                    <small class="red--text">
                      <v-icon color="red">mdi-alert</v-icon> Анхны хадгаласнаас
                      хойш дүн засагдсан байна, ЭСИС-т xадгалагдаагүй байх
                      магадлалтай. Менежер даxин xадгалаx товчоо дарж ЭСИС-тай
                      тулгаарай.
                    </small>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      class="py-4"
      persistent
      v-model="showStudentScores"
      max-width="60%"
      v-if="showStudentScores && selectedStudent"
    >
      <v-card>
        <v-card-title class="headline black--text">
          <v-row>
            <v-col> Суралцагчийн дүнгийн нэгтгэл </v-col>
            <v-col class="text-end">
              <v-btn @click="_printScores" class="red" elevation="0" dark
                >Xэвлэx</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <div id="printMe">
          <v-card-text class="mt-4 px-10">
            <div class="text-h5 tableHeader" style="background-color: yellow">
              <span v-if="selectedStudent" class="font-weight-bold; black--text"
                >Суралцагч: {{ selectedStudent.FIRST_NAME }}</span
              >, {{ selectedStudent.LAST_NAME }},
              <span class="blue--text"
                >{{
                  selectedStudent["DATE_OF_BIRTH"].replace(
                    "T00:00:00.000Z",
                    ""
                  )
                }}, Бүлэг: {{ currentClassGroup["STUDENT_GROUP_NAME"] }} /{{
                  userData.school.currentYear
                }}/</span
              >
            </div>

            <table class="px-10 scoreBorderedTable mb-4" style="width: 100%">
              <thead>
                <tr>
                  <th class="scoreTableTodorhoilolt-hcell" style="width: 4%">
                    No.
                  </th>
                  <th class="scoreTableTodorhoilolt-hcell">Xичээл</th>
                  <th class="scoreTableTodorhoilolt-hcell">
                    Жилийн эцсийн дүн
                  </th>
                  <th class="scoreTableTodorhoilolt-hcell">
                    Жилийн эцсийн дүнгийн түвшин
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(eLesson, lIndex) in eeljitLessons"
                  :key="eLesson.id + lIndex"
                >
                  <td class="scoreTableTodorhoilolt-cell">{{ lIndex + 1 }}</td>
                  <td class="scoreTableTodorhoilolt-cell">
                    {{ eLesson.courseInfo.COURSE_NAME }}
                  </td>
                  <td class="scoreTableTodorhoilolt-cell">
                    <span
                      v-for="(unelgee2, uIndex) in _getScore(
                        selectedStudent,
                        eLesson
                      )"
                      :key="uIndex + 'score'"
                      >{{ unelgee2.scoreFinal }}
                    </span>
                  </td>
                  <td class="scoreTableTodorhoilolt-cell">
                    <span
                      v-for="(unelgee2, uIndex) in _getScore(
                        selectedStudent,
                        eLesson
                      )"
                      :key="uIndex + 'score'"
                      >{{ unelgee2.scoreLevelString }}
                    </span>
                  </td>
                  <td class="scoreTableTodorhoilolt-cell">
                    <span
                      v-for="(unelgee2, uIndex) in _getScore(
                        selectedStudent,
                        eLesson
                      )"
                      :key="uIndex + 'score'"
                      >{{ unelgee2.scoreLevelString }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </div>
        <v-card-actions class="pb-10 mx-6">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            class="text-capitalize"
            @click="
              selectedStudent = null;
              showStudentScores = !showStudentScores;
            "
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

export default {
  components: {},
  data: () => ({
    showStudentScores: false,
    isWithScoreLevel: false,
    eeljitLessons: null,
    selectedTab: 0,
    selectedStudent: null,

    currentClassGroup: null,
    loading: false,
    myClass: null,
    myDepartment: null,
    selectedDepartment: null,
    selectedClassGroup: null,
    departments: null,
    selected: [],
    excelLoading: false,
    myStudents: null,
    scoreLevels: [
      {
        name: "I",
        level: 1,
      },
      {
        name: "II",
        level: 2,
      },
      {
        name: "III",
        level: 3,
      },
      {
        name: "IV",
        level: 4,
      },
      {
        name: "V",
        level: 5,
      },
      {
        name: "VI",
        level: 6,
      },
      {
        name: "VII",
        level: 7,
      },
      {
        name: "VIII",
        level: 8,
      },
    ],
    finishedLoading: false,

    selectedGradingMethod: 0,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр шалгалтын дүнгээр",
        value: 1,
      },
    ],
    showingOnlyApproved: false,
    classScoreNumber: {
      averageScoreSum: null,
      gradedScores: null,
      classAverageScore: null,
    },
    selectedHalfYear: null,
    preText: null,
    scoresLoading: false,
    printing: false,
  }),
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
    selectedHalfYearId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },
    students2() {
      var list = [];
      if (this.myStudents != null) {
        var counter = 0;
        for (var item of this.myStudents) {
          item.name2 = this._getPrefix(item);

          if (!item.moved) {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }

    this.currentClassGroup = this.zClassGroup;
    this.school.ref
      .collection("students-" + this.school.currentYear)
      .orderBy("firstName", "asc")
      .where(
        "STUDENT_GROUP_ID",
        "==",
        this.currentClassGroup["STUDENT_GROUP_ID"]
      )
      .onSnapshot((querySnapshot) => {
        this.myStudents = [];
        var counter = 0;
        querySnapshot.forEach((doc) => {
          counter++;
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          item.DISPLAY_NAME = item["LAST_NAME"]
            ? item["LAST_NAME"][0] + "." + item["FIRST_NAME"]
            : null;

          console.log(item.DISPLAY_NAME);
          item.bd = this._getBirthDay(item);
          item.index = counter;
          item.name3 =
            counter +
            ". " +
            item.DISPLAY_NAME +
            ", " +
            item["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "");
          this.myStudents.push(item);
        });
      });
    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);
    if (this.selectedHalfYearId) {
      this.selectedHalfYear = {
        yearId: this.selectedHalfYearId,
        name:
          this.preText +
          " xичээлийн жил, " +
          this.selectedHalfYearId +
          "-р xагас жил",
      };
    } else {
      this.selectedHalfYear = {
        yearId: 2,
        name: this.preText + " xичээлийн жил, " + 2 + "-р xагас жил",
      };
    }
  },
  watch: {
    selectedStudent(val) {
      this._getStudentScores(val);
    },
    selectedGradingMethod() {
      this.calculateScore(false);
    },
    finishedLoading(val) {
      if (val == true) {
        this.calculateScore(false);
      }
    },
    selectedHalfYear(val) {
      val.name =
        this.preText + " xичээлийн жил, " + val.yearId + "-р xагас жил";
      console.log(val);
      this._setupp();
    },
  },
  methods: {
    _getStudentScores(student) {
      this.scoresLoading = true;
      student.lessonGrades = {};
      var loadingCounter = 0;
      this.eeljitLessons.forEach(async (eeljitLesson) => {
        await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? eeljitLesson.id + "-" + this.selectedHalfYear.yearId
              : eeljitLesson.id
          )
          .collection("scores")
          .where("FIRST_NAME", "==", student.FIRST_NAME)
          .where("LAST_NAME", "==", student.LAST_NAME)
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            student.lessonGrades[eeljitLesson.id] = [];
            docs.forEach((doc) => {
              let studentScore = doc.data();
              studentScore.ref = doc.ref;
              studentScore.id = doc.id;
              if (Number(studentScore.PERSON_ID) == Number(student.PERSON_ID))
                student.lessonGrades[eeljitLesson.id].push(studentScore);
            });

            if (
              student.lessonGrades[eeljitLesson.id] &&
              student.lessonGrades[eeljitLesson.id].length > 1
            ) {
              var tmpScore = 0;
              var approvedAt = null;
              var approved = true;
              if (
                typeof eeljitLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
                "number"
              ) {
                student.lessonGrades[eeljitLesson.id].forEach((score) => {
                  if (eeljitLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0) {
                    tmpScore = tmpScore + score.scoreFinal;
                  } else {
                    if (tmpScore < score.scoreFinal) {
                      tmpScore = score.scoreFinal;
                    }
                  }
                  if (
                    !approvedAt ||
                    score.approvedAt.toDate() > approvedAt.toDate()
                  ) {
                    approvedAt = score.approvedAt;
                  }

                  if (!score.approved) {
                    approved = false;
                  }
                });

                if (eeljitLesson.GRADING_METHOD_USED_FOR_FINAL_GRADE == 0) {
                  tmpScore = Math.round(
                    tmpScore / student.lessonGrades[eeljitLesson.id].length
                  );
                }

                let gradeStr;
                if (tmpScore >= 90) {
                  gradeStr = "VIII";
                } else if (tmpScore >= 80) {
                  gradeStr = "VII";
                } else if (tmpScore >= 70) {
                  gradeStr = "VI";
                } else if (tmpScore >= 60) {
                  gradeStr = "V";
                } else if (tmpScore >= 50) {
                  gradeStr = "IV";
                } else if (tmpScore >= 40) {
                  gradeStr = "III";
                } else if (tmpScore >= 30) {
                  gradeStr = "II";
                } else if (tmpScore >= 0 && typeof tmpScore == "number") {
                  gradeStr = "I";
                }

                student.lessonGrades[eeljitLesson.id] = [
                  {
                    scoreFinal: tmpScore,
                    PERSON_ID: student.PERSON_ID,
                    scoreLevelString: gradeStr,
                    approvedAt: approvedAt,
                    approved: approved,
                  },
                ];
              }
            }

            loadingCounter++;
            if (loadingCounter == docs.size) {
              setTimeout(() => {
                this.scoresLoading = false;
              }, 1000);
            }
          });
      });
    },
    async checkIfTypeChanged(lesson, mode) {
      if (mode == 2) {
        var docSize = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(lesson.id)
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            return docs.docs.filter((doc) => !doc.data().lessonGroupId).length;
          });
        return docSize;
      } else if (mode == 1) {
        docSize = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? lesson.id + "-" + this.selectedHalfYear.yearId
              : lesson.id
          )
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            console.log(docs.size);
            return docs.docs.filter((doc) => doc.data().lessonGroupId).length;
          });
        return docSize;
      }
    },
    _getBirthDay(student) {
      return student["DATE_OF_BIRTH"]
        ? student["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
        : "";
    },
    async _printScores() {
      setTimeout(() => {
        //  await this.$htmlToPaper('printMe');
        var divToPrint = document.getElementById("printMe");
        var htmlToPrint =
          "" +
          '<style type="text/css">' +
          ".tableHeader { padding-left: 50px!important; padding-top:100px; padding-bottom:10px; font-size:12pt}" +
          "table {border-collapse: collapse; max-width:80%; margin-left:50px;}" +
          "table th {" +
          "border: 1px solid black;" +
          "font-size:11pt" +
          "}" +
          "table td {" +
          "font-size:11pt;" +
          "border: 1px solid black;" +
          "border-spacing: 0px !important; padding: 4px; 2px  4px 2px" +
          "}" +
          "</style>";
        htmlToPrint += divToPrint.outerHTML;
        var newWin = window.open("");
        newWin.document.write(htmlToPrint);
        newWin.print();
        newWin.close();
        setTimeout(() => {
          this.printing = false;
        }, 500);
      }, 500);
    },
    _getSortIndex(input) {
      let result;
      switch (input) {
        case 2:
          result = 1;
          break;
        case 4:
          result = 2;
          break;
        case 3:
          result = 3;
          break;
        case 245:
          result = 4;
          break;
        case 5:
          result = 5;
          break;
        case 196:
          result = 6;
          break;
        case 10:
          result = 7;
          break;
        case 9:
          result = 8;
          break;
        case 11:
          result = 9;
          break;
        case 13: //tuuh
          result = 10;
          break;
        case 194: //tuuh
          result = 10;
          break;
        case 195:
          result = 11;
          break;
        case 12:
          result = 12;
          break;
        case 24:
          result = 13;
          break;
        case 25:
          result = 13;
          break;
        case 23:
          result = 14;
          break;
        case 22:
          result = 15;
          break;
        case 233:
          result = 16;
          break;
        default:
          result = 0;
      }
      return result;
    },
    _getSortIndex2(input) {
      //dund angi
      let result;
      switch (input) {
        case 2:
          result = 1;
          break;
        case 4:
          result = 2;
          break;
        case 3:
          result = 3;
          break;
        case 5:
          result = 4;
          break;
        case 196:
          result = 5;
          break;
        case 10:
          result = 6;
          break;
        case 11:
          result = 7;
          break;
        case 9:
          result = 8;
          break;
        case 245:
          result = 9;
          break;
        case 12:
          result = 10;
          break;
        case 194:
          result = 11;
          break;
        case 13:
          result = 11;
          break;
        case 195:
          result = 12;
          break;
        case 15:
          result = 13;
          break;
        case 20:
          result = 14;
          break;
        case 17:
          result = 15;
          break;
        case 18:
          result = 16;
          break;
        case 23:
          result = 17;
          break;
        case 22:
          result = 18;
          break;
        case 24:
          result = 19;
          break;
        case 25:
          result = 20;
          break;
        default:
          result = 0;
      }
      return result;
    },
    _setupp() {
      this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .where(
          "programRef",
          "==",
          fb.db.doc(
            this.currentClassGroup.ref.path.replace(
              "departments-2022",
              "departments-"+this.userData.school.currentYear
            )
          )
        )
        .onSnapshot((docs) => {
          console.log(docs.size, this.currentClassGroup.ref.path);
          this.eeljitLessons = [];
          docs.forEach(async (doc) => {
            let eeljitLesson = doc.data();
            eeljitLesson.id = doc.id;
            eeljitLesson.ref = doc.ref;
            if (Number(eeljitLesson.classGroups[0].ACADEMIC_LEVEL) >= 10) {
              eeljitLesson.sortIndex = this._getSortIndex(
                eeljitLesson.courseInfo.SUBJECT_AREA_ID
              );
            } else {
              eeljitLesson.sortIndex = this._getSortIndex2(
                eeljitLesson.courseInfo.SUBJECT_AREA_ID
              );
            }
            this.eeljitLessons.push(eeljitLesson);
          });
          this.eeljitLessons.sort(
            (a, b) =>
              b.courseInfo.ENROLLMENT_CATEGORY.length -
                a.courseInfo.ENROLLMENT_CATEGORY.length ||
              a.sortIndex - b.sortIndex
          );
        });
    },
    _print0() {
      console.log(this.currentClassGroup.ref.path);
    },
    _showNote() {
      if (
        this.currentClassGroup["ACADEMIC_LEVEL"] &&
        parseInt(this.currentClassGroup["ACADEMIC_LEVEL"]) > 9
      )
        return true;
      else return false;
    },
    _showLessonInfo(eLesson) {
      var xx = null;
      for (const tt of eLesson.byTeachers) {
        xx = xx ? xx + ", " + tt.teacherDisplayName : tt.teacherDisplayName;
      }
      this.$swal({
        title: eLesson.courseInfo.COURSE_NAME,
        text: "Багш: " + xx,
      });
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            item.firstName + ". " + item.lastName.substring(0, item.prefixName);
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name = item.firstName + ". " + item.lastName[0];
        else name = item.firstName;
      }
      return name;
    },
    _detail() {},
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 1px 4px;
  text-align: left;
  border: 1px solid #ddd;
  /* Change this to your desired border style */
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
  /* Change this to your desired background color */
}
.scoreBorderedTable {
  border-collapse: collapse;
}
.scoreTableTodorhoilolt-hcell {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
  font-weight: bold !important;
}
.scoreTableTodorhoilolt-cell {
  border: 1px solid black;
  text-align: left;
  border-spacing: 0px !important;
  padding: 0px;
  padding-left: 10px;
  cursor: pointer;
}
</style>
